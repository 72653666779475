.btn-primary {
	background-color: transparent;
	border: 1px solid;
	text-transform: lowercase;
	padding: 14px 34px;
	border-radius: 70px;
	font-family: $fontBasic;
	@include antialiased;
	font-size: 18px;
	letter-spacing: 0.05em;
	line-height: 31px;
	@include media-breakpoint-up(sm) {
		font-size: 20px;
		padding: 18px 34px;
	}
	@include media-breakpoint-up(md) {
		font-size: 26px;
		padding: 22px 42px;
	}
	&.smaller {
		font-size: 17px;
		line-height: 24px;
		border-radius: 30px;
		padding: 5px 16px;
		@include media-breakpoint-up(md) {
			padding: 7px 23px;
			font-size: 20px;
		}
	}
	&.dark {
		color: $textDark;
		border-color: $textDark;

		background-image: linear-gradient($textDark, $textDark 100%);
		background-position: center bottom;
		background-size: 100% 0%;
		background-repeat: no-repeat;
		transition: all 0.2s;
		svg line,
		svg path {
			transition: all 0.2s;
		}
		&:hover,
		&:active,
		&:focus {
			background-size: 100% 100%;
			color: #f5efed;
			svg line,
			path {
				stroke: #f5efed;
			}
		}
	}
	&.light {
		color: $textLight;
		border-color: $textLight;

		background-image: linear-gradient($textLight, $textLight 100%);
		background-position: center bottom;
		background-size: 100% 0%;
		background-repeat: no-repeat;
		transition: all 0.2s;
		&:hover,
		&:active,
		&:focus {
			background-size: 100% 100%;
			color: #0f3c37;
			svg line,
			svg path {
				stroke: #0f3c37;
			}
		}
	}

	svg {
		margin-left: 14px;
		position: relative;
		left: 0;
		transition: all 0.2s;
		@include media-breakpoint-down(sm) {
			transform: scale(0.8);
			transform-origin: center;
			margin-left: 10px;
		}
	}
	&:hover {
		svg {
			left: 4px;
		}
	}
}

.btn-underline {
	font-size: 18px;
	line-height: 1em;
	font-family: $fontBasic;
	@include antialiased;
	letter-spacing: 0.05em;
	color: #ffffff;
	@include media-breakpoint-up(md) {
		font-size: 23px;
	}

	span {
		display: inline-block;
		margin-right: 10px;
		@include multilineUnder(rgba(#fff, 0.3), 100%, 1px);
		background-size: 100% 1px;
		transition: all 0.2s;
		@include media-breakpoint-up(md) {
			margin-right: 20px;
		}
	}
	svg {
		position: relative;
		left: 0;
		transition: all 0.2s;
		@include media-breakpoint-down(sm) {
			transform: scale(0.8);
		}
	}
	&:hover,
	&:focus,
	&:visited {
		color: #fff !important;
		svg {
			left: 4px;
		}
		span {
			background-size: 0% 1px;
		}
	}
}

.btn-fullArrow {
	font-size: 17px;
	line-height: 23px;
	letter-spacing: 0.04em;
	@include antialiased;
	color: #0f413c;
	display: inline-flex;
	align-items: center;
	@include media-breakpoint-up(md) {
		font-size: 19px;
	}
	span.arrow {
		width: 34px;
		height: 34px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #0f413c;
		margin-right: 10px;
		&:before {
			width: 60%;
			height: 60%;
			content: '';
			display: inline-block;
			background: url(/img/arrow-down-small.svg) center no-repeat;

			background-size: contain;
		}
	}
	span:not(.arrow) {
		display: inline-block;
		@include underline(1px, 2px, rgba(#0f413c, 0.2));
		&:before {
			width: 100%;
			transition: all 0.2s;
		}
	}
	&:hover {
		span:not(.arrow) {
			&:before {
				width: 0%;
			}
		}
	}
}

footer {
	@include padding-top(120px);
	padding-bottom: 20px;
	color: white;
	background-color: #0c302c;
	.container-fluid {
		@include media-breakpoint-up(md) {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
	/* .bitworksLogo {
		@include ir;
		display: inline-block;
		width: 25px;
		height: 25px;
		background: url('/img/bitworks.svg') 0 0 no-repeat;
		background-size: contain;
		vertical-align: middle;
		opacity: 0.2;
		position: relative;
		top: -5%;
		margin-left: 2px;
	} */
	.top {
		@include margin-bottom(120px);
		svg {
			@include media-breakpoint-down(sm) {
				margin-bottom: 3em;
			}
		}
		.rightPart {
			a {
				font-size: 20px;
				line-height: 1em;
				letter-spacing: 0.05em;
				color: rgba(#fff, 0.4);
				display: block;
				@include antialiased;

				white-space: nowrap;
				@include media-breakpoint-up(md) {
					font-size: 23px;
				}
			}
			#footerMail {
				display: inline-block;
				margin-top: 20px;
				margin-bottom: 20px;
				@include underline(1px, 4px, rgba(#fff, 0.1));
			}
		}
	}
	.bottom,.social_links {
		small,
		a, button {
			font-family: $fontBasic;
			text-transform: uppercase;
			color: rgba(#fff, 0.3);
			font-size: 14px;
			@include antialiased;
			letter-spacing: 0.06em;
			line-height: 100%;
			margin: 5px 0;
			@include media-breakpoint-up(md) {
				line-height: 214%;
				margin: 0;
			}
		}
    button{
      background-color: transparent;
      border: none;
      outline: none;
      margin-left: 10px;
    }
	}
  .social_links{
    margin-bottom: 10px;
    a{
      margin-right: 16px;
    }
  }
}

header {
	margin: 10px 0;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	@include media-breakpoint-up(md) {
		margin: 20px 0;
	}

	a {
		transition: color 0.4s;
		text-transform: lowercase;
		&:focus,
		&:hover,
		&:visited {
			color: $textLight;
		}
	}
	.col {
		display: flex;
		align-items: center;
	}
	.musicPart {
		justify-content: flex-start;
		@include media-breakpoint-up(md) {
			padding-left: 0;
		}
		a {
			font-size: 17px;
			line-height: 23px;
			letter-spacing: 0.05em;
			margin-left: 11px;
			display: inline-block;
			position: relative;
			color: $textLight;
      @include antialiased;
			@include media-breakpoint-up(md) {
				font-size: 20px;
			}
			span:not(.m_hide) {
				position: absolute;
				display: inline-block;
				top: 50%;
				left: -11px;
				width: calc(100% + 22px);
				transform: translateY(-50%);
				height: 1px;
				background-color: rgba(#fff, 0.5);
				transition: all 0.4s;
				clip-path: inset(0 0 0 0);
			}
			&:hover {
				span:not(.m_hide) {
					clip-path: inset(0 10px 0 10px) !important;
				}
			}
		}
	}
  .leftPart{
    a{
      text-decoration: underline;
      text-underline-offset: 8px;
      text-decoration-thickness: 1px;
      text-decoration-color: rgba(#fff, 0.3);
      text-transform: initial;
      span.m_hide{
        display: none;
        @include media-breakpoint-up(md) {
          display: inline;
        
        }
      }
    }
  }
	.logoPart {
		justify-content: center;
		h1 {
			text-align: center;
			font-size: 43px;
			line-height: 1em;
			margin: 0;
			letter-spacing: 0.02em;
			color: $textLight;
			transition: color 0.4s;
			a {
				color: $textLight;
				.char:nth-child(2) {
					transition: transform 0.4s;
					transform: translateY(0) rotate(0deg);
				}
			}

			&:hover {
				a {
					.char:nth-child(2) {
						transform: rotate(180deg) translateY(-4px);
					}
				}
			}
		}
	}
	.menuPart {
		justify-content: flex-end;
		text-align: right;
		@include media-breakpoint-up(md) {
			padding-right: 0;
		}

		a.btn-primary {
			background-image: linear-gradient($textLight, $textLight 100%);
			background-position: center bottom;
			background-size: 100% 0%;
			background-repeat: no-repeat;
			transition: all 0.2s;
			&:hover,
			&:active,
			&:focus {
				background-size: 100% 100%;
				color: $textDark;
			}
			@include media-breakpoint-down(sm) {
				padding: 1px 13px;
				font-size: 16px;
			}
		}
	}
}

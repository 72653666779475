$primary: #0f3c37; // primary brand color
$secondary: #f5efed;
$textLight: #fffef1;
$textDark: #0f3c37;
$bonus: #e4e5df;
$g: #4affff; // guide lines
$placeholder: #142120; // image placeholder
$formColor: #0f413c;

$fontPrimary: 'Reg', serif;
$fontItalic: 'Reg Italic', serif;
$fontBasic: 'Simplon', sans-serif;

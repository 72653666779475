/* open-sans-300 - latin_latin-ext */
@font-face {
	font-family: 'Simplon';
	font-style: normal;
	font-weight: 300;
	src: url('/assets/fonts/simplonnorm-regular-webfont.woff') format('woff');
	src: url('/assets/fonts/simplonnorm-regular-webfont.woff2') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'Reg';
	font-style: normal;
	font-weight: 700;
	src: url('/assets/fonts/font.woff') format('woff');
	src: url('/assets/fonts/font.woff2') format('woff2');
	font-display: swap;
}
@font-face {
	font-family: 'Reg Italic';
	font-style: normal;
	font-weight: 700;
	src: url('/assets/fonts/font-it.woff') format('woff');

	font-display: swap;
}

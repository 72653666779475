#pageHome {
	/* SPLIT-TYPE */
	.line {
		display: block;
		overflow: hidden;
	}
	.line .innerLine {
		position: relative;
	}
	.sectionHeading {
		.line:first-of-type .char:first-of-type {
			&::first-letter {
				font-family: $fontItalic;
				font-style: normal;
			}
		}
		&.moveFirst {
			.line:first-of-type .char:first-of-type {
				&::first-letter {
					padding-right: 16px;
				}
			}
		}
	}

	.overflowNormal,
	.overflowNormal .line {
		overflow: visible;
	}
	/* SPLIT-TYPE */

	.par-b {
		@include font-size(34px);
		line-height: 131%;
		letter-spacing: 0.01em;
		opacity: 0.9;
		font-family: $fontBasic;
		font-weight: normal;
		@include antialiased;
		@include media-breakpoint-up(md) {
			line-height: 146%;
		}
	}
	.par-n {
		font-family: $fontBasic;
		letter-spacing: 0.03em;
		@include font-size(15px);
		line-height: 140%;
		@include antialiased;
		@include media-breakpoint-up(md) {
			@include font-size(20px);
			line-height: 160%;
		}
	}
	.container-fluid {
		@include media-breakpoint-up(md) {
			padding-left: 30px;
			padding-right: 30px;
			.row {
				margin-left: 0;
				margin-right: 0;
			}
		}
	}

	.scrollspy-example {
		h2 {
			@include font-size(113px);
			line-height: 105%;
			@include media-breakpoint-up(md) {
				@include font-size(143px);
			}

			& > small {
				@include font-size(14px);
				line-height: 26px;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				letter-spacing: 0.08em;
				opacity: 0.9;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				font-family: $fontBasic;
				@include antialiased;
				margin-bottom: 1em;
				margin-left: 12px;
				@include media-breakpoint-up(md) {
					@include font-size(26px);
					width: 66px;
					height: 66px;
					margin-bottom: 0;
					margin-left: 0;
				}
			}

			span.move {
				@include media-breakpoint-up(md) {
					position: relative;
					left: 1em;
				}
			}
		}
		.materials {
			h2 {
				line-height: 105%;
				i:first-of-type {
					display: inline-block;
					margin-left: 10px;
					@include media-breakpoint-up(md) {
						margin-left: 44px;
					}
				}
			}
		}
		.usporadani {
			h2 {
				line-height: 96%;
				i:first-of-type {
					display: inline-block;
					margin-right: 10px;

					@include media-breakpoint-up(md) {
						margin-left: 20px;
						margin-right: 20px;
					}
				}
			}
		}
		.variabilita {
			h2 {
				@include margin-bottom(30px);
			}
		}
	}

	section.newTop {
		position: relative;
		@include padding-bottom(234px);
		padding-top: 6em;
		@include media-breakpoint-up(md) {
			padding-top: 10em;
		}

		.scrollWrap {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 100vh;
			pointer-events: none;
			z-index: 1;

			.smooth-scroll {
				position: absolute;

				bottom: 45px;
				left: 30px;
				width: 66px;
				height: 66px;
				border-radius: 50%;
				border: 1px solid $textLight;
				display: flex;
				justify-content: center;
				align-items: center;
				pointer-events: all;
				@include media-breakpoint-down(sm) {
					transform: scale(0.8);
					transform-origin: left;
					bottom: 25px;
					left: 20px;
				}
				svg {
					position: relative;
					transition: all 0.2s;
					top: 0px;
				}
				&:hover {
					svg {
						top: 4px;
					}
				}
			}
		}
		h1 {
			position: relative;
			z-index: 1;
			line-height: 94%;
			margin: 0;
			@include font-size(130px);
			@include media-breakpoint-up(md) {
				@include font-size(150px);
			}
			.line:first-of-type .word:first-of-type {
				&::first-letter {
					font-family: $fontItalic;
					font-style: normal;
				}
			}
		}

		.imageWrap {
			position: relative;
			top: -1em;
			background-color: transparent;
			overflow: hidden;
			width: 100vw;
			height: 100vw;
			margin-left: auto;
			margin-right: auto;
			@include media-breakpoint-up(md) {
				top: -3em;
				width: 75vw;
				height: 75vw;
			}

			.img {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				top: 0;
				width: 100%;
				height: 100%;
				background-color: transparent;
				img {
					top: -5%;
					@include media-breakpoint-up(md) {
						transform-origin: top;

						top: -6%;
					}
				}
			}
			.mask {
				position: absolute;
				width: 70vw;
				height: 90vw;
				border-radius: 142px;
				bottom: 2em;
				left: 50%;
				transform: translateX(-50%);
				box-shadow: 0 0 0 99999px $primary;
				cursor: pointer;

				@include media-breakpoint-up(md) {
					width: 45vw;
					height: 64vw;
					border-radius: 442px;
					bottom: 10em;
				}

				@media screen and (min-height: 1000px) {
					&:after {
						content: '';
						display: block;
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						background: linear-gradient(0deg, #0f3c37 32.67%, rgba(15, 60, 55, 0) 100%);
						height: 40%;
					}
				}
			}
		}
		.text {
			margin: 2em 0 2em 0;
			@include media-breakpoint-up(sm) {
				margin: 4em 0 2em 0;
			}

			p.main {
				@include font-size(54px);
				line-height: 110%;
				@include antialiased;
				margin-bottom: 1.6em;
				@include media-breakpoint-up(sm) {
					line-height: 131%;
					@include font-size(74px);
				}
				@include media-breakpoint-up(lg) {
					margin: 0 auto 1.6em auto;
					width: 89%;
				}

				.img {
					position: relative;
					display: inline-block;
					vertical-align: sub;
					-webkit-backface-visibility: hidden;
					@include media-breakpoint-down(sm) {
						display: none !important;
					}
					&[data-order='0'] {
						width: 127px;
						height: 102px;
						transform: rotate(-5.1deg);
						margin: 0 23px 0 0px;
					}
					&[data-order='1'] {
						width: 122px;
						height: 109px;
						transform: rotate(-81deg);
						margin: 0 -4px 0 15px;
					}
					&[data-order='2'] {
						width: 142px;
						height: 100px;
						transform: rotate(4.8deg);
						margin: 0 15px 0 5px;
						top: 5px;
					}
					img {
						@include cover;
					}
				}
			}

			a {
				@include media-breakpoint-up(md) {
					font-size: 25px;
					margin: 0 7px;
				}
				@include media-breakpoint-down(md) {
					display: inline-block;
					margin: 7px 0;
				}
				&.cenik {
					border-color: rgba(#fffef1, 0.4);
				}
			}
		}
	}

	section.materials {
		@include padding-top(197px);
		@include padding-bottom(167px);

		/* marquee on scroll */
		#marquee {
			position: relative;
			left: -100vw;
			@include font-size(122px);
			white-space: nowrap;
			-webkit-text-stroke: 1px rgba($color: $textDark, $alpha: 0.5);
			color: rgba(15, 60, 55, 0);
			@include margin-top(200px);
			@include antialiased;
			svg {
				margin: 0 40px;
				.fill {
					fill: rgba(15, 60, 55, 0);
				}
				@include media-breakpoint-down(sm) {
					transform: scale(0.8);
					margin: 0 20px;
				}
			}
		}
		.rightPart {
			@include media-breakpoint-up(md) {
				@include padding-left(70px);
			}
		}
		.leftPart,
		.rightPart {
			@include media-breakpoint-up(md) {
				padding: 0;
			}
		}
		.topRow {
			.leftPart,
			.rightPart {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				.img {
					background-color: white;
				}
			}
			.leftPart {
				.img {
					width: 70%;
					@include ratio(117%);
					transform-origin: bottom left;
					@include media-breakpoint-up(md) {
						@include margin-top(130px);
					}
					@include media-breakpoint-down(sm) {
						margin-left: auto;
						margin-right: 0;
					}
				}
			}
			.rightPart {
				@include media-breakpoint-down(sm) {
					margin-top: 2em;
				}

				.img {
					transform-origin: top right;
					width: 100%;
					@include ratio(124%);
				}
			}
		}
		.bottomRow {
			@include margin-top(130px);
			p {
				padding-right: 0;
				padding-left: 5em;
				@include media-breakpoint-up(md) {
					//padding-right: 6em;
					padding-left: 0;
				}
				@include media-breakpoint-down(sm) {
					margin-bottom: 4em;
				}
			}
		}
		.accordion {
			.accordion-item {
				border-top: 1px solid $textDark;
				&:hover {
					.accordion-button > span {
						background-color: rgba($textDark, 0.8);
					}
				}
				&.opened {
					.accordion-button > span svg {
						transform: rotate(180deg);
					}
					.accordion-button > span {
						background-color: rgba($textDark, 0.8);
					}
				}
				h3 {
					padding: 0;
				}
				button {
					@include antialiased;
					@include font-size(24px);
					letter-spacing: 0.02em;
					line-height: 43px;
					padding-left: 0;
					padding-top: 12px;
					padding-bottom: 12px;
					@include media-breakpoint-up(md) {
						@include font-size(38px);
						padding-top: 22px;
						padding-bottom: 28px;
					}
				}
			}
			.accordion-button > span {
				margin-right: 35px;
				min-width: 58px;
				min-height: 58px;
				background-color: $textDark;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				transition: background-color 0.2s;
				@include media-breakpoint-down(sm) {
					margin-right: 15px;
					transform: scale(0.8);
				}
				svg {
					transform: rotate(0deg);
					transition: all 0.4s;
				}
			}
			.accordion-body {
				padding: 1em 0 40px 1em;
			}
		}
    .virtual_tour{
      margin-top: 4em;
      margin-bottom: 2em;
      @include media-breakpoint-up(md) {
        margin-top: 8em;
        margin-bottom: 6em;
      }
      h3{
        text-align: center;
        @include antialiased;
       
        span{
          font-size: 52px;
          color:white;
          display: block;
          line-height: normal;
          @include media-breakpoint-up(md) {
            font-size: clamp(64px, 5.8vw, 100px);
          }
        }
      }
      .wrap_outer{
        position: relative;
        @include media-breakpoint-up(md) {
          padding: 0;
        }
        .wrap{
          width: 100%;
          aspect-ratio: 3/4;
          position: relative;
          overflow: hidden;
          margin-top: 1em;
          @include media-breakpoint-up(md) {
            aspect-ratio: 1379/844;
            margin-top: 5em;
          }
          .overlay{
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url('/img/vr_bg.jpg') no-repeat center;
            background-size: cover;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            visibility: visible;
            opacity: 1;
            transition: all 0.2s;
            &.hidden{
              visibility: hidden;
              opacity: 0;
            }
            button{
              display: flex;
              align-items: center;
              background-color: #FFFEF1;
              border: none;
              padding: 15px 22px;
              border-radius: 60px;
              @include media-breakpoint-up(md) {
                padding: 20px 28px;
              }
              span{
                font-family: 'Simplon', sans-serif;
                font-size: 18px;
                letter-spacing: 0.05em;
                line-height: 1;
                color: #0F413C;
                text-transform: lowercase;
                line-height: 1;
                @include antialiased;
                margin-inline: 12px;
                @include media-breakpoint-up(md) {
                  font-size: 21px;
                  margin-inline: 20px;
                }
              }
              &:after{
                display: inline-block;
                content: '';
                width: 19px;
                height: 23px;
                background: url('/img/arr_btn.svg') no-repeat center;
                background-size: contain;
              }
            }
           
          }
        }
        button#leave_tour{
          position: absolute;
          left: 50%;
          bottom: -20px;
          transform: translateX(-50%) translateY(100%);
          z-index: 10;
          display: flex;
          align-items: center;
          background-color: #FFFEF1;
          border: none;
          padding: 15px 22px;
          border-radius: 60px;
          @include media-breakpoint-up(xxl) {
            transform: none;
            padding: 20px 28px;
            bottom: 10px;
            left: 10px;
          }
          &.hidden{
            display: none;
          }
          span{
            white-space: nowrap;
            font-family: 'Simplon', sans-serif;
            font-size: 16px;
            letter-spacing: 0.05em;
            line-height: 1;
            text-transform: lowercase;
            line-height: 1;
            @include antialiased;
            margin-inline: 12px;
            color:#0f3c37;
            
            @include media-breakpoint-up(md) {
              font-size: 18px;
            }
          }
          &:before{
            display: inline-block;
            content: '';
            width: 19px;
            height: 23px;
            background: url('/img/door.svg') no-repeat center;
            background-size: contain;
            }
        }
      }
    }
	}

	section.usporadani {
		@include padding-top(190px);
		@include padding-bottom(210px);

		.twoPars {
			.par-b {
				width: 90%;
			}
			.par-n {
				padding-left: 4em;
				@include media-breakpoint-up(md) {
					padding-right: 2em;
				}
			}
		}
		.fullImg {
			@include media-breakpoint-up(md) {
				padding: 0;
			}

			.img {
				@include ratio(73%);
				@include margin-top(130px);
				@include margin-bottom(100px);
				width: 100%;
				@include media-breakpoint-up(md) {
					@include ratio(61%);
				}
			}
		}
		.claim {
			@include margin-bottom(30px);
			#quote {
				display: inline-block;
				svg {
					@include media-breakpoint-down(sm) {
						transform: scale(0.7);
					}
				}
			}
			p {
				@include font-size(40px);
				line-height: 125%;

				@include antialiased;
				letter-spacing: 0;
				@include media-breakpoint-up(md) {
					@include font-size(55px);
				}
				.line:first-of-type {
					&::first-letter {
						font-family: $fontItalic;
						font-style: normal;
						margin-right: 3px;
					}

					text-indent: 70px;
					@include media-breakpoint-up(md) {
						text-indent: 180px;
					}
				}
			}
		}
		.author {
			font-family: $fontBasic;
			@include media-breakpoint-down(sm) {
				padding-right: 20px;
			}
			.name {
				font-size: 17px;
				letter-spacing: 0.05em;
				line-height: 1em;
				display: inline-block;
				color: $textLight;
				@include antialiased;
				position: relative;
				top: -10px;
				@include media-breakpoint-up(md) {
					font-size: 20px;
				}

				small {
					opacity: 0.5;
					text-transform: lowercase;
					display: block;
				}
			}
			.img {
				display: inline-block;
				width: 88px;
				max-width: 88px;
				height: 88px;
				border-radius: 50%;
				margin-right: 10px;
				@include media-breakpoint-up(md) {
					width: 108px;
					max-width: 108px;
					height: 108px;
					margin-right: 20px;
				}
			}
		}
	}

	section.variabilita {
		@include padding-top(190px);
		@include padding-bottom(260px);
		/* tiny slider */
    //counter
    .sliderCount{
      display: flex;
      justify-content: center;
      margin-top: 0em;
      @include media-breakpoint-up(lg) {
        margin-top: 2em;
      }
      .current, .total,.slash{
        font-family: 'Simplon', sans-serif;
        font-size: 19px;
        letter-spacing: 0.05em;
        line-height: 1;
        color: #0F3C37;
        @include antialiased;
        opacity: 0.6;
        @include media-breakpoint-up(lg) {
          font-size: 22px;
        }
      }
      .slash{
        margin-inline: 8px;
      
      }
    }
		.tns-inner {
			.plan-slider {
				.slide {
					.wrap {
						position: relative;
						@include media-breakpoint-down(sm) {
							padding-bottom: 30px;
						}
						.img {
							@include ratio(67%);
							background-color: white;
						}
						.desc {
							position: absolute;
							bottom: 0px;
							left: 0px;
							font-family: $fontBasic;
							color: #0f413c;
							opacity: 0.6;
							font-size: 14px;
							letter-spacing: 0.03em;
							@include media-breakpoint-up(md) {
								font-size: 17px;
								writing-mode: vertical-rl;
								transform: rotate(180deg) translateX(100%);
								left: -10px;
								bottom: 0;
							}
						}
					}
				}
			}
		}
		.tns-outer {
			position: relative;
			.tns-controls {
				position: absolute;
				z-index: 1;
				top: 50%;
				width: 100%;
				button {
					/* remoe styling */
					border: none;
					background-color: transparent;
					outline: none;
					box-shadow: none;
					transform: translateY(-50%);
					position: absolute;
					width: 103px;
					height: 103px;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: transparent;
          //background-color: #f5efed;
					border: none;
					background-image: url(/img/arrow-slider.svg);
          background-position: center;
          background-repeat: no-repeat;
					background-size: contain;
					@include media-breakpoint-down(sm) {
						width: 63px;
						height: 63px;
						transform: translateY(-70%);
					}

					&[data-controls='prev'] {
            left: 10px;
            @include media-breakpoint-up(lg) {
              left: calc(50% - 655.5px);
            }
						
						
					}
					&[data-controls='next'] {
            right: 10px;
						
						transform: rotate(180deg) translateY(50%);
						@include media-breakpoint-up(lg) {
            right: calc(50% - 655.5px);
						}
					}
				}
			}
		}
		.nav-pills {
			@include margin-bottom(80px);
			@include media-breakpoint-down(sm) {
				padding-left: 12px;
			}
			li {
				&:first-child {
					padding-right: 6px;
				}
				&:last-child {
					padding-left: 6px;
				}
				@include media-breakpoint-down(sm) {
					&:first-child {
						padding-right: 3px;
					}
					&:last-child {
						padding-left: 3px;
					}
				}
			}
			button {
				border-color: $textDark;
				background-color: transparent;
				color: $textDark;
				padding-left: 50px;
				padding-right: 50px;
				@include media-breakpoint-down(sm) {
					padding: 8px 34px;
				}

				&.active {
					border-color: $textDark;
					background-color: $textDark;
					color: $textLight;
				}
			}
		}
		.tab-content {
			margin-bottom: 2em;
			@include media-breakpoint-up(md) {
				@include margin-bottom(140px);
			}

			.leftPart > p {
				padding-right: 2em;
			}
			.rightPart > p {
				padding-left: 5em;
				@include media-breakpoint-up(md) {
					padding-right: 1em;
					padding-left: 0;
				}
			}

			.rightPart > .links {
				@include media-breakpoint-down(md) {
					padding-left: 5em;
				}
				a {
					margin-right: 50px;
					margin-bottom: 10px;
				}
			}
		}

    .cenik{
      border-bottom: 1px solid #0F3C37;
      margin-top: 8em;
      margin-inline:12px;
      @include media-breakpoint-up(xl) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-inline:80px;
        margin-top: 14em;
      
      }
      .left{
        padding-bottom: 2em;
        text-align: center;
        @include media-breakpoint-up(md) {
          padding-bottom: 4em;
        }
        @include media-breakpoint-up(xl) {
          padding-left: 2em;
          border-right: 1px solid #0F3C37;
          text-align: left;
          padding-bottom: 0em;
        }
        h3{
          margin-top: 0;
          margin-bottom: 1em;
          @include media-breakpoint-up(lg) {
            margin-bottom: 2.3em;
          }
          span,strong{
            display: block;
            line-height: 1;
          }
          span.title{
            font-family: 'Simplon',sans-serif;
            @include antialiased;
            font-size: 23px;
            letter-spacing: 0.01em;
            opacity: 0.9;
            @include media-breakpoint-up(lg) {
              font-size: 28px;
            }
          }
          span.dph{
            font-family: 'Simplon',sans-serif;
            @include antialiased;
            font-size: 20px;
            letter-spacing: 0.03em;
            opacity: 0.5;
            @include media-breakpoint-up(lg) {
              font-size: 22px;
            }

          }
          strong{
            font-size:50px;
            white-space: nowrap;
            letter-spacing: 0.04em;
            line-height: 137%;
            @include media-breakpoint-up(lg) {
              font-size: 65px;
            }
            @include media-breakpoint-up(xxl) {
              font-size: 80px;
            }
          }
        }
        a{
          background-color: #0F3C37;
          border: 1px solid #0F3C37;
        }
      }
      .right{
        padding: 2em 0em 3.5em 0em;
        @include media-breakpoint-up(lg) {
          padding: 0px 4em 3.5em 4em;
        }
        @include media-breakpoint-up(xxl) {
          padding: 0px 7em 5em 8em;
        }
        .nav-pills{
          background-color: white;
          margin: 0;
          padding-inline: 4px;
          padding-block: 4px;
          overflow: hidden;
          width: fit-content;
          border-radius:60px;
          margin-inline: auto;
          @include media-breakpoint-up(xl) {
            margin-inline: 0;
          }
          li{
            padding: 0;
          }
          button{
            font-size: 17px;
            letter-spacing: 0.01em;
            line-height: 1;
            padding: 0;
            border: none;
            color: rgba(#0F413C,0.58);
            padding: 17px 15px;
            width: 100%;
            @include media-breakpoint-up(lg) {
              padding: 17px 20px;
              font-size: 19px;
            }
            @include media-breakpoint-up(xl) {
              padding: 17px 18px;
              font-size: 18px;
            }
            @include media-breakpoint-up(xxl) {
              padding: 17px 32px;
              font-size: 20px;
            }
            &.active{
              background: #0F413C;
              color: #fffef1;
            }
          }
        }
        .tab-content{
          margin-bottom: 0;
          ul{
            padding: 0;
            margin: 0;
            list-style: none;
            margin-top: 2em;
            padding-left: 1em;
            padding-right: 1em;
            @include media-breakpoint-up(lg) {
              padding-right: 2em;
              padding-left: 1em;
            }
            li{
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              font-family: 'Simplon',sans-serif;
              @include antialiased;
              padding-block: 0.4em;
              svg{
                min-width: 26px;
                width: 26px;
              }
              span{
                font-size: 18px;
                color: rgba(#0F413C, 0.9);
                letter-spacing: 0.01em;
                margin-left: 22px;
                @include media-breakpoint-up(lg) {
                  font-size: 20px;
                }
              }
            }
          }
        }
      }
    }
	}

	section.zajem {
		position: relative;
		//@include padding-top(170px);
    padding-top: 0;
		@include padding-bottom(220px);
		hr {
			position: absolute;
			top: 0;
			left: 20px;
			right: 20px;
			background-color: $textDark;
			opacity: 1;
			@include media-breakpoint-up(md) {
				left: 44px;
				right: 44px;
			}
		}
		h2 {
			@include font-size(96px);
			line-height: 115%;
			margin: 0;
			@include margin-bottom(30px);
		}
		p {
			@include font-size(18px);
			letter-spacing: 0.01em;
			line-height: 147%;
			opacity: 0.9;
			font-family: $fontBasic;
			@include antialiased;
			@include margin-bottom(100px);
			@include media-breakpoint-up(md) {
				@include font-size(30px);
			}
		}
    a:last-of-type{
      display: inline-block;
      margin-top: 1em;
      line-height: 1;
      @include media-breakpoint-up(md) {
        margin-top: 2em;
      }
    }
	}

	section.about {
		@include padding-top(145px);
		@include padding-bottom(145px);

		overflow: hidden;
		.container-fluid,
		.rightPart {
			@include media-breakpoint-up(md) {
				padding-right: 0;
			}
		}
		h2 {
			@include font-size(126px);
			line-height: 87%;
			color: #fff;
			margin: 0;
			@include margin-top(55px);
			@include margin-bottom(70px);
		}
		p {
			@include margin-bottom(70px);

			padding-left: 5em;
			@include media-breakpoint-up(md) {
				@include padding-right(20px);
				padding-left: 0;
			}
		}
		.img {
			width: 85%;
			@include ratio(111%);
			transform-origin: bottom right;

			@include media-breakpoint-up(md) {
				width: 100%;
			}
			img {
				transform-origin: bottom right;
			}
		}
		.btn-underline {
			display: block;
			text-align: right;

			position: relative;

			@include media-breakpoint-up(md) {
				@include padding-right(20px);
				left: -20px;
			}
		}
	}
}

.errorPage {
	background-color: $primary;
	color: $textLight;

	header {
		margin: 0;
		padding: 10px 0 0 0;
		position: static;
		@include media-breakpoint-up(md) {
			padding: 20px 0 0 0;
		}
		.container-fluid {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
	section {
		padding: 0;
		&.top {
			height: calc(100vh - 64px);
			position: relative;
			.wrap {
				text-align: center;
				@include centerElement;
			}
			h1 {
				font-size: flexible-font(50, 140, 768, 2280);
				width: 100vw;
				margin-bottom: 0.8em;
			}
		}
	}

	footer {
		display: none;
	}
}

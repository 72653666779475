#pageForm {
	background-color: white;
	/* min-height: 800px; */
	min-height: 100vh;
	position: relative;
	/* overflow: hidden; */

	.flexWrap {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-wrap: wrap;
		.leftPart,
		.rightPart {
			flex: 1;
		}
		.leftPart {
			display: flex;
			flex-direction: column;
			position: relative;
			padding-left: 10px;
			padding-right: 10px;
			padding-bottom: 20px;
			@include media-breakpoint-up(md) {
				padding-left: 30px;
				padding-right: 40px;
				padding-bottom: 30px;
			}
			#back {
				position: absolute;
				left: 3%;
				top: 3%;
				text-transform: lowercase;
				font-size: 15px;
				letter-spacing: 0.09em;
				color: $textDark;
				@include media-breakpoint-up(md) {
					font-size: 17px;
				}
				&:hover {
					svg {
						left: -4px;
					}
				}
				svg {
					transform: rotate(180deg);
					margin-right: 8px;
					width: 21px;
					height: 17px;
					position: relative;
					left: 0;
					transition: left 0.2s;
					@include media-breakpoint-down(sm) {
						margin-right: 4px;
						transform: scale(0.9) rotate(180deg);
						transform-origin: center;
					}
				}
			}
		}
		.rightPart {
			display: none;
			position: relative;
			.logo {
				font-size: 43px;
				line-height: 1em;
				letter-spacing: 0.02em;
				color: $textLight;
				font-family: $fontBasic;
				z-index: 1;
				position: absolute;
				right: 30px;
				top: 22px;
				@include antialiased;
			}
			@include media-breakpoint-up(lg) {
				display: flex;
			}
		}
		.img {
			width: 100%;
			height: 100%;
			position: relative;
		}

		a.cenik {
			position: absolute;
			bottom: 25px;
			right: 25px;
			background-color: white;
			border-radius: 36px;
			display: flex;
			padding: 16px 54px 16px 21px;
			letter-spacing: 0.05em;
			align-items: center;
			font-size: 17px;
			@include media-breakpoint-up(md) {
				font-size: 19px;
			}
			span.underline {
				display: inline-block;
				@include underline(1px, -1px, rgba(#0f413c, 0.2));
			}
			span.arrow {
				width: 38px;
				height: 38px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #0f413c;
				margin-right: 17px;
				&:after {
					width: 55%;
					height: 55%;
					content: '';
					display: inline-block;
					background: url(/img/arrow-down-small.svg) center no-repeat;
					background-size: contain;
				}
			}
			small {
				display: block;
				opacity: 0.5;
				margin-top: 5px;
				font-size: 12px;
			}
		}
	}
	#my-form-status {
		margin: 0;
		display: none;
		font-family: $fontBasic;
		color: $formColor;
		font-size: 21px;
		line-height: 139%;
		opacity: 0.9;
	}
	.wpcf7-spinner {
		display: none;
	}
	.wpcf7-response-output {
		border: none;
		font-family: $fontBasic;
		padding: 0;
	}
	.wpcf7-not-valid-tip {
		font-family: $fontBasic;
		color: $formColor;
		padding-top: 5px;
	}
	h1 {
		color: $textDark;
		@include font-size(94px);
		line-height: 141%;
		margin-bottom: 0;
		@include margin-top(150px);
		margin-left: 10px;
		@include media-breakpoint-up(md) {
			margin-bottom: 30px;
			margin-left: 20px;
		}
	}
	p#main {
		font-family: $fontBasic;
		color: $formColor;
		font-size: 15px;
		line-height: 139%;
		opacity: 0.9;
		@include media-breakpoint-up(md) {
			font-size: 17px;
		}
	}
	::placeholder {
		color: rgba($formColor, 0.5);
	}
	input:not([type='submit']),
	textarea,
	select {
		display: block;
		border: none;
		outline: none;
		box-shadow: none;
		font-family: $fontBasic;
		width: 100%;
		background-color: transparent;
		font-size: 15px;
		line-height: 88.3%;
		letter-spacing: 0.09em;
		color: $formColor;
		@include media-breakpoint-up(md) {
			font-size: 17px;
		}
	}
	textarea {
		padding-left: 16px;
		padding-top: 38px;
		min-height: 90px;
		@include media-breakpoint-up(md) {
			min-height: 120px;
		}
	}
	input:not([type='submit']) {
		padding-left: 12px;
		padding-top: 25px;
		padding-bottom: 19px;
		border-bottom: 1px solid $formColor;
		@include media-breakpoint-up(md) {
			padding-left: 16px;
			padding-top: 38px;
			padding-bottom: 23px;
		}
	}

	span[data-name='tel-306'],
	span[data-name='email-447'] {
		@include media-breakpoint-up(md) {
			width: calc(50% - 12px);
			display: inline-block;
		}
	}
	span[data-name='tel-306'] {
		@include media-breakpoint-up(md) {
			float: right;
		}
	}

	select {
		background: url(/img/select.svg) 95% center no-repeat;
		@include media-breakpoint-down(sm) {
			background-position-x: 92%;
			background-size: 3%;
		}
		border: 1px solid $formColor;
		padding: 23px 28px 21px 23px;
		border-radius: 50px;
		-moz-appearance: none;
		/* for Chrome */
		-webkit-appearance: none;
		@include media-breakpoint-up(md) {
			padding: 27px 32px 25px 27px;
		}
	}
	input[type='submit'] {
		border: none;
		outline: none;
		box-shadow: none;
		width: 100%;
		background-color: $formColor;
		font-size: 17px;
		line-height: 88.3%;
		letter-spacing: 0.09em;
		text-transform: uppercase;
		color: #ffffff;
		font-family: $fontBasic;
		padding-top: 20px;
		padding-bottom: 20px;
		border-radius: 36px;
		@include media-breakpoint-up(md) {
			padding-top: 25px;
			padding-bottom: 25px;
			font-size: 19px;
		}
	}
}

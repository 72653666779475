#cc-main{
  --cc-font-family: "Simplon", sans-serif;
  --cc-modal-border-radius: 60px;
  --cc-btn-border-radius: 30px;
  --cc-modal-transition-duration: .25s;
  --cc-modal-margin: 1rem;
  --cc-z-index: 2147483647;
  --cc-primary-color: #0F413C;
  --cc-btn-primary-bg: #0F413C;
  --cc-btn-primary-border-color: #0F413C;
  --cc-btn-primary-hover-bg: #0F413C;
  --cc-bg: #F5EFED;



    --cc-bg: #F5EFED;
    --cc-primary-color: #0F413C;
    --cc-secondary-color: #0F413C;

    --cc-btn-primary-bg: #0F413C;
    --cc-btn-primary-color: #ffffff;
    --cc-btn-primary-border-color: var(--cc-btn-primary-bg);
    --cc-btn-primary-hover-bg: #0F413C;
    --cc-btn-primary-hover-color: #ffffff;
    --cc-btn-primary-hover-border-color: var(--cc-btn-primary-hover-bg);

    --cc-btn-secondary-bg: #F5EFED;
    --cc-btn-secondary-color: var(--cc-primary-color);
    --cc-btn-secondary-border-color: var(--cc-btn-secondary-bg);
    --cc-btn-secondary-hover-bg: #F5EFED;
    --cc-btn-secondary-hover-color: #0F413C;
    --cc-btn-secondary-hover-border-color: #F5EFED;

    --cc-separator-border-color: #F5EFED;

    --cc-toggle-on-bg: var(--cc-btn-primary-bg);
    --cc-toggle-off-bg: #677977;
    --cc-toggle-on-knob-bg: #ffffff;
    --cc-toggle-off-knob-bg: var(--cc-toggle-on-knob-bg);

    --cc-toggle-enabled-icon-color: var(--cc-bg);   // yes (v tick)
    --cc-toggle-disabled-icon-color: var(--cc-bg);  // no (x tick)

    --cc-toggle-readonly-bg: #d5dee2;
    --cc-toggle-readonly-knob-bg: #fff;
    --cc-toggle-readonly-knob-icon-color: var(--cc-toggle-readonly-bg);

    --cc-section-category-border: var(--cc-cookie-category-block-bg);

    --cc-cookie-category-block-bg: white;
    --cc-cookie-category-block-border: white;
    --cc-cookie-category-block-hover-bg: white;
    --cc-cookie-category-block-hover-border: white;
    --cc-cookie-category-expanded-block-bg: white;
    --cc-cookie-category-expanded-block-hover-bg: white;

    --cc-overlay-bg: transparent;
    --cc-webkit-scrollbar-bg: var(--cc-section-category-border);
    --cc-webkit-scrollbar-hover-bg: var(--cc-btn-primary-hover-bg);

    --cc-footer-bg: var(--cc-btn-secondary-bg);
    --cc-footer-color: var(--cc-secondary-color);
    --cc-footer-border-color: #e4eaed;
    .cm--cloud{
      max-width: 576px;
      @include media-breakpoint-down(md) {
       
        border-radius: 10px;
      }
    }
  .cm__body{
    padding: 7px 8px 7px 16px;
    @include media-breakpoint-down(md) {
      padding-top: 1.2em!important;
    }
    
    .cm__texts{
      padding: 0;
      .cm__desc{
        display: none;
      }
      .cm__title{
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #0F413C;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 1; 
        letter-spacing: 0.03em;
        svg{
          margin-right: 10px;
          width: 24px;
          max-width: 24px;
        }
      }
    }
    .cm__btns{
      flex-direction: row-reverse!important;;
      align-items: center;
      padding: 0;
      @include media-breakpoint-down(md) {
        padding-top: 1em;
        padding-bottom: 0.5em;
      }
      .cm__btn-group{
        display: block;
      }
      button{
        padding: 7px 18px;
        font-size: 16px!important;
        letter-spacing: 0.03em;
        font-weight: 300;
        -webkit-font-smoothing: antialiased;
        text-transform: lowercase;
        line-height: 1!important;
        min-width: unset!important;
      }
      button[data-role="necessary"]{
        display: none;
      }
      .cm__btn--secondary{
        text-decoration: underline;
        text-decoration-color: rgba(#0F413C,0.3);
        text-underline-offset: 6px;
        font-size: 15px;
        padding-block: 0;
        position: relative;
        top: -3px;
      }
    }
  }
  .cm__footer{
    display: none;
  }

  .pm.pm--box.pm--flip{
    border-radius: 10px;
  }
  .pm__title{
    font-weight: 300;
    font-family: "Simplon", sans-serif;
    font-size: 37px;
    line-height: 147.3%;
    letter-spacing: 0.01em;
    //margin-bottom: 0.35em;
    @include media-breakpoint-down(md) {
      font-size: 27px;
    }
  }
  .pm__section--toggle.pm__section--expandable{
    border-radius: 10px;
  }
  .pm__section-title{
    font-weight: 300;
    font-family: "Simplon", sans-serif;
    font-size: 27px;
    line-height: 147.3%;
    letter-spacing: 0.01em;
    margin-bottom: 0.35em;
    @include media-breakpoint-down(md) {
      font-size: 22px;
    }
  }
  .pm__section-desc{
    line-height: 119%;
    letter-spacing: .03em;
    font-size: 17px;
    @include media-breakpoint-down(md) {
      font-size: 15px;
    }
  }
  .pm__section-title-wrapper{
    button.pm__section-title{
      font-size: 19px;
      font-weight: 300;
      @include media-breakpoint-down(md) {
        font-size: 16px;
      }
    }
    .pm__badge.pm__service-counter{
      font-family: "Simplon", sans-serif;
      font-size: 14px;
    }
  }
  .pm__footer{
    button{
      padding: 7px 18px;
      font-size: 16px;
      letter-spacing: 0.03em;
      font-weight: 300;
      -webkit-font-smoothing: antialiased;
    }
  }
}
// GLOBAL STYLES
// essentially anything
// ( html, body, h1 - h6, strong, em )
// -----------------------------------

// Test if gulp autoprefix works
#autoprefixer-test {
	display: grid;
	transition: all 0.5s;
	user-select: none;
	background: linear-gradient(to bottom, white, black);
}
html {
	background-color: white;
}
body {
	font-family: $fontPrimary;
	background-color: #fff;
	color: #222;
	position: relative;
	&.darkenHead {
		header {
			a {
				color: $textDark;
				&#menuAudio {
					span {
						background-color: rgba($textDark, 0.5);
					}
				}
			}
			h1 {
				color: $textDark;
				a {
					color: $textDark;
				}
			}
			.menuPart {
				.btn-primary {
					border-color: $textDark;
					color: $textDark;

					background-image: linear-gradient($textDark, $textDark 100%);
					background-position: center bottom;
					background-size: 100% 0%;
					background-repeat: no-repeat;
					transition: all 0.2s;
					&:hover {
						background-size: 100% 100%;
						color: $textLight;
					}
				}
			}
		}
	}
	&.hideNav {
		#nav {
			opacity: 0 !important;
			/* visibility: hidden; */
		}
	}
	&.darkenNav {
		#nav {
			.meter {
				background-color: rgba($textDark, 0.23);
				#innerOne,
				#innerTwo,
				#innerThree {
					position: absolute;
					width: 100%;
					height: 0%;
					top: 0;
					background-color: $textDark;
				}
			}
			a {
				color: $textDark;
				span:not(.num) {
					background-color: $primary;
					color: $textLight;
				}
			}
		}
	}
	&.playing {
		header .musicPart > a > span {
			clip-path: inset(0 100% 0 0) !important;
		}
		#cursor {
			.play {
				visibility: hidden;
				opacity: 0;
				transition: all 0.4s;
			}
			.stop {
				visibility: visible;
				opacity: 1;
				transition: all 0.4s;
			}
		}
	}
}
i {
	font-family: $fontItalic;
	font-style: normal;
}

h1,
h2,
h3,
h4 {
	margin-bottom: 0.6em;
	margin-top: 0.9em;
	line-height: 1.1em;
}

h1 {
	font-size: 4em;
	@include antialiased;
	font-size: calc(40px + 3vw);
}

h2 {
	font-size: 2.5em;
	font-size: calc(40px + 1.5vw);
	small {
		font-size: 30px;
		vertical-align: super;
	}
}

h3 {
	font-size: 2em;
}

h4 {
	font-size: 1.5em;
}

a {
	color: $primary;
	text-decoration: none;
	font-family: $fontBasic;
	&:hover,
	&:focus,
	&:active,
	&:visited {
		color: $primary;
	}
}

p {
	margin-bottom: 2rem;
	letter-spacing: 0.01em;
}

::selection {
	background-color: $primary;
	color: $textLight;
}

section {
	overflow: hidden;
	padding: 4em 0;
	margin: 0;
	@include media-breakpoint-up(md) {
		padding: 8em 0;
	}
	&.dark {
		background-color: $primary;
		color: $textLight;
		::selection {
			background-color: $secondary;
			color: $textDark;
		}

		h2 > small {
			border: 1px solid $textLight;
			@include media-breakpoint-up(md) {
				border: 2px solid $textLight;
			}
		}
	}
	&.light {
		background-color: $secondary;
		color: $textDark;

		h2 > small {
			border: 1px solid #0f413c;
			@include media-breakpoint-up(md) {
				border: 2px solid #0f413c;
			}
		}
	}
	&.bonus {
		background-color: $bonus;
		color: $textDark;
	}
}
/* side nav */
#nav {
	position: absolute;
	right: 25px;
	bottom: 30px;
	z-index: 999;
	border-radius: 0;
	height: 110px;
	justify-content: space-evenly;
	transition: all 0.4s;
	opacity: 1;
	visibility: visible;
	pointer-events: all;
	@include media-breakpoint-up(md) {
		height: 165px;
		right: 30px;
		bottom: 45px;
		position: fixed;
	}

	.meter {
		width: 1px;
		height: 100%;
		background-color: rgba($textLight, 0.23);
		transition: background-color 0.4s;
		overflow: hidden;
		border-radius: 20px;
		position: absolute;
		right: -10px;
		top: 0;
		opacity: 1;
		transition: opacity 0.4s;
		#innerOne,
		#innerTwo,
		#innerThree {
			position: absolute;
			width: 100%;
			height: 0%;
			top: 0;
			background-color: $textLight;
		}
	}

	.list-group-item {
		background: transparent;
		&.active {
			span.num {
				opacity: 1;
			}
		}
		&:hover {
			span.num {
				opacity: 1;
			}
		}
	}
	&:hover {
		a > span:not(.num) {
			visibility: visible;
			opacity: 1;
		}
	}

	a {
		border: none;
		display: inline-block;
		padding: 0;
		margin: 5px 0;
		display: flex;
		justify-content: flex-end;
		color: #e8e7e1;
		transition: color 0.4s;
		font-size: 10px;
		letter-spacing: 0.05em;
		@include antialiased;
		@include media-breakpoint-up(md) {
			font-size: 15px;
			margin: 10px 0;
		}
		span:not(.num) {
			visibility: hidden;
			opacity: 0;
			transition: all 0.4s;
			position: relative;
			left: -15px;
			background-color: $secondary;
			padding: 7px 17px;
			color: $textDark;
			border-radius: 20px;
			@include media-breakpoint-down(sm) {
				display: none;
			}
		}
		span.num {
			display: flex;
			justify-content: center;
			align-items: center;
			opacity: 0.5;
			transition: all 0.4s;
		}
	}
}

/* images */
.img,
picture.img {
	position: relative;
	overflow: hidden;
	@include ratio(60%);
	background-color: $placeholder;
	img {
		@include cover;
	}
}
picture {
	display: inline-block;
}
.lazy {
	opacity: 0;
	visibility: hidden;
	transition: all 0.2s;
}
.entered.loaded {
	opacity: 1;
	visibility: visible;
}

/* accordion remove styling */
.accordion-item,
.accordion-button {
	border-radius: 0;
	background-color: transparent;
	border: none;
	color: $primary;
	box-shadow: none;
}
.accordion-button:focus,
.accordion-button:not(.collapsed) {
	background-color: transparent;
	border: none;
	color: $primary;
	box-shadow: none;
}
.accordion-button:after {
	display: none;
}
.accordion-header {
	margin-top: 0;
}
.accordion-item:first-of-type {
	border-radius: 0;
}

#cursor {
	z-index: 1;
	position: fixed;
	left: 0;
	top: 20%;
	transform: scale(0);
	opacity: 0;
	background-color: $textLight;

	width: 121px;
	height: 121px;
	border-radius: 50%;
	cursor: pointer;
	pointer-events: none;
	z-index: 999;
	display: inline-block;
	pointer-events: none;

	@include media-breakpoint-down(sm) {
		display: none;
	}

	span.wave {
		display: inline-block;
		max-width: 60px;
		width: 60px;
		height: 19px;
		overflow: hidden;
		position: relative;
		clip-path: inset(0% 0% 0% 0%);
	}
	span.play,
	span.stop {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-family: $fontBasic;
		color: $textDark;
		font-size: 16px;
		letter-spacing: 0.03em;
		text-align: center;
		line-height: 19px;
	}
	span.stop {
		visibility: hidden;
		opacity: 0;
		transition: all 0.4s;
	}
	span.play {
		visibility: visible;
		opacity: 1;
		transition: all 0.4s;
	}
}
